.colorButtons{
    border-radius:50%;
    height:3vh;
    width:6vw !important;
    margin:0;
    background-color: red;
}
.sizeButtons{
    border-radius: 10%;
    height:5vh;
    width: 5vh !important;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    // background-color: red;
}
.sizeLabel{
    align-self: center;
    margin: auto;
    font-weight: bold;
    font-size: 1.8vh;
}
.slick-active{
    width: 5vh !important;
    margin: 2vw;
}
.slick-slide{
    width: auto !important;
    margin: 2vw;
}
:focus{
    outline: none !important;
}
.imageColor{
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    margin-left: 2vw;
}
.titleProductDetail{
    text-align: center;
    font-weight: bold;
    color:#28255A;
    font-size: 2vh ;
}
.available{
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 1vh;
    height: fit-content;
}
.productInfo{
    color: #28255A;
    font-size: 1.8vh;
}

.model{
    color: #28255A;
    font-size: 2vh;
    margin-left: 5vw;
}



.originalPrice{
    color: #28255A;
    text-decoration: line-through;
    font-size: 2vh;
    font-weight: bold;
}

.salePrice{
    color: red;
    font-size: 2vh;
    font-weight: bold;
}

.price{
    color: #28255A;
    font-size: 2vh;
    font-weight: bold;
}

.imageSliderContainer{
    height: 35vh;
    width: 100%;
    background-color: white;
}

.imageSlider{
    width:100%;
    height:100%;
    object-fit: contain;
}
.zonesMsg{
    font-weight: bold;
    color:#ffffff;
    font-style: italic;
    font-size: 2.1vh;
    padding: 1.5vh;
    width: 100%;
    margin: 1vh;
    text-align: center;
}
.availableLabel{
    font-size: 1.8vh;
    color:#ffffff;
    align-self: center;
    margin-bottom: 0px;
}
.btnAddProduct{
    background-color: #ABD157;
    height:5vh;
    width: 80vw;
    margin-top: 2vh;
    margin: auto;
    color:#ffffff;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-bottom: 2vh;
    flex-direction: row;
    display: flex;
    border: none;
    font-size: 1.8vh;
    font-weight: bold;

}

.ril-image-current{
    background-color: white;
}

.ril__navButtons{
    display: none;
}
.ril__inner{
    background:white
}
