.containerOrderHistory{
    background-color: white;
    border-radius: 5px;
    margin:2vh;
    -webkit-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.15);
    -moz-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.15);
    box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.15);
}
.txtOrder{
    font-size: 1.8vh;
    color: #22213E;
    font-weight: bold;
    padding-left: 2vw;
}
.txtInfoOrder{
    font-size: 1.8vh;
    color:#22213E;
}