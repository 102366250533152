.btnAdd{
    background-color: #ABD157;
    height:5vh;
    width: 80vw;
    margin-top: 2vh;
    margin: auto;
    color:#ffffff;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-bottom: 2vh;
    flex-direction: row;
    display: flex;
    border: none;
    font-size: 1.8vh;
    font-weight: bold;
}

