.orderProductContainer{
    background-color: #ffffff;
    border-radius: 2px;
    margin: 0 5vw 2vh 5vw;
    -webkit-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.15);
    -moz-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.15);
    box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.15);
}

.imageOrderProduct{
    height: 10vh;
    width: 10vh;
    margin-right: 2vw;
    align-self: center;
}

.orderProductInfo{
    font-size: 2vh;
    color: #22213E;
}

.orderProductPrice{
    margin-right: 2vh;
    margin-top: 0;
    font-weight: bold;
    color: #28255A;
    font-size: 2vh;
}