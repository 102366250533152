.containerSearchModel{
    display: flex;
    padding: 0 5vh;
    justify-content: center;
    align-content: center;
    height: 90vh;
    flex-direction: column;
    text-align: center;
}
.titleSearchModel{
    font-size: 6vw;
    color: #28255A;
    text-align: center;
    font-weight: bold;
}
.descriptionSearchModel{
    font-size: 4vw;
    color: #28255A;
}
.btnSearchProduct{
    margin-top: 5vh !important;
    background-color: #ABD157;
    height:10vw;
    width: 80vw;
    margin: auto;
    color:#ffffff;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-bottom: 2vh;
    flex-direction: row;
    display: flex;
    border: none;
    font-size: 3vw;
    font-weight: bold;
}
.inputSearchModel{
    display: flex;
    padding: 0vw 2vw;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    height: 10vw;
    border:2px solid #DDDDE3;
}