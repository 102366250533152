.containerTopNavBar{
    padding-top: 1.5vh;
    height:7vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nameScreen{
    font-size: 3vh;
    color:#28255A ;
    font-weight: bold;
    margin: 0 0 0 5vw;
}

.iconFareli{
    position: absolute;
    right: 5vw;
}