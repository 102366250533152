.msgEmptyCart{
    font-size: 2vh;
    color: #28255A;
    font-weight: bold;
    margin: auto;
}

.btnConfirm{
    background-color:#ABD157 ;
    width: 100%;
    height: 5vh;
    color: white;
    font-size: 1.8vh;
    font-weight: bold;
    margin: auto;
    border: none;
    border-radius: 5px;
}
.btnExit{
    font-size: 1.8vh;
    color: #28255A;
    text-decoration: underline;
    font-weight: bold;
    background-color: white;
    border: none;
}

.txtModalTitle{
    font-size: 2vh;
    color: #28255A;
    font-weight: bold;
    text-align: center;
}
.txtTotalItems{
    font-size: 2vh;
    color:#28255A;
}

.txtTotalPrice{
    font-size: 2.8vh;
    color: #28255A;
    font-weight: bold;
}