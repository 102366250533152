.image1x2{
    width:100%;
    height:100%;
    object-fit: cover;
    background-color: white;
    padding: 2px;
}

.pricePosition1x2{
    height:3vh;
    position: absolute;
    width:10vw;
    // backgroundColor:"white",
    // borderRadius:50,
    color:red;
    justify-content: center;
}

.image1x1{
    width:100%;
    height:100%;
    object-fit: cover;
    background-color: white;
    padding: 2px;
}

.pricePosition1x1{
    height:3vh;
    position: absolute;
    width:10vw;
    // backgroundColor:"white",
    // borderRadius:50,
    color:red;
    justify-content: center;
}

.priceProduct{
    font-size: 3.5vw;
    color:#28255A;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.image2x2{
    width:100%;
    height:100%;
    object-fit: cover;
    padding: 2px;

}
.pricePosition2x2{
    height:3vh;
    position: absolute;
    width:10vw;
    // backgroundColor:"white",
    // borderRadius:50,
    color:red;
    justify-content: center;
}
.etiquetaTop{
    top:5vw;
    width:12vw;
    left: -1vw;
    position: absolute;
}

.etiquetaBot{
    bottom:0vh;
    width:12vw;
    left: -1vw;
    position: absolute;
}

.txtPriceDiscountTop{
    font-weight: bold;
    text-align:center;
    font-size:3vw;
    color: #FFFFFF;
    top:6vw;
    left: 2.2vw;
    position: absolute;
}

.txtPriceDiscountBot{
    font-weight: bold;
    text-align:center;
    font-size: 3vw;
    color: #FFFFFF;
    top:-5.1vw;
    left: 2.2vw;
    position: absolute;
}

.container2x2Image{
    width:100vw;
    height:100vw;
    display:flex;
    justify-content: center;
}

.container2x2Video{
    width:100vw;
    height:auto;
    display:flex;
    justify-content: center;
}
.container1x2Image{
    width:50vw;
    height:100vw;
    display:flex;
    justify-content: center;
}
.container1x2Video{
    width:50vw;
    height:100vw;
    display:flex;
    // justify-content: center;
}

video{
    object-fit: cover;
}