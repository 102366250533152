.orderDetailTitle{
    font-size: 2vh;
    font-weight: bold;
    color:"#22213E",
}

.orderDetailLabel{
    font-size: 2vh;
    color:"#22213E",
}

.orderDetailStatus{
    font-size: 1.5vh;
    font-weight: bold;
    align-self: center;
}
.orderDetailCancel{
    font-size: 1.5vh;
    font-weight: bold;
    align-self: center;
    color: #EB5757;
    text-decoration: underline;
}

.totalContainer{
    background-color: #28255A;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 12vw;
    width: 100vw;
}

.totalLabel{
    font-size: 2vh;
    font-weight: bold;
    color:white;
    align-self: center;
    margin: 1.5vh;
    margin-right: 3vh;

}