.btnSection {
    width: 65vw;
    height: 10vh;
    background-color: #28255A;
    display: flex;
    border-top: 1px solid #393681;
    border-bottom: 1px solid #393681;
    justify-content: center;
}
.btnSection2 {
    width: 65vw;
    height: 10vh;
    background-color: #FFFFFF;
    display: flex;
    border-top: 1px solid #CDCACD;
    border-bottom: 1px solid #CDCACD;
    justify-content: center;
}
.txtSection {
    color: white;
    align-self: center;
    margin: 0;
}
.txtSection2 {
    color: black;
    align-self: center;
    margin: 0;
}
.txtGender2 {
    color: white;
    align-self: center;
    margin: 0;
}
.btnGender {
    width: 65vw;
    height: 10vh;
    background-color: #19183A;
    display: flex;
    justify-content: center;
}
.btnGender2 {
    width: 65vw;
    height: 10vh;
    background-color: #000000;
    display: flex;
    justify-content: center;
}