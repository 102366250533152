.textDepartment{
    color:#28255A;
    margin-left: 2vw;
}

.textGender{
    color:#28255A;
    margin-right: 2vw;
}

.searchDepartment{
    display: flex;
    justify-content: center;
}

.divisor{
    height: 2.7vh;
    width: .5vw;
    margin: 0 1vw;
    background-color: #ABD157;
}

.divisor2 {
    height: 2.7vh;
    width: .5vw;
    margin: 0 1vw;
    background-color: #87425F;
}
.emptyResults{
    font-size: 2vh;
    color: #28255A;
    font-weight: bold;
}
.labelCategory{
    font-size: 1.5vh;
    color:#28255A;
}