.containerProductResults{
    background-color: #fff;
    border-radius: 2px;
    margin-bottom: 1vh;
    margin-right:1.5vw ;
    margin-left:1.5vw ;
    padding: .5vh 2vh;
    -webkit-box-shadow: 7px 6px 14px -5px rgba(0,0,0,0.29);
    -moz-box-shadow: 7px 6px 14px -5px rgba(0,0,0,0.29);
    box-shadow: 3px 3px 11px -5px rgba(0,0,0,0.29);
}

.imageProductResults {
    height: 10vh;
    width: 10vh;
    //         height:hp('10%'),
//         width:hp('10%'),
//         marginLeft:wp('2%')
}
.codeModeProductResults{
    font-size: 1.5vh;
    font-weight: bold;
    margin-left: 2vw;
    color: #22213E;
    // fontSize:hp('2%'),
    //         marginLeft:wp('2%'),
    //         color:"#22213E",
    //         fontFamily:"muli-bold",
}
.nameModelProductResults{
    font-size: 1.5vh;
    color: #28255A;
    margin-left: 2vw;
}