.ordersBox{
    display: flex;
    background-color: #ffffff;
    height: 10vh;
    margin-left: 5vw;
    margin-right: 5vw;
    justify-content: center;
    -webkit-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.15);
-moz-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.15);
box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.15);
    border-radius: 5px;
}

.labelOrders{
    font-size: 2vh;
    font-weight: bold;
    text-decoration: underline;
}

.infoBox{
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    margin:5vh 2vh 5vh 2vh;
    justify-content: center;
}
.infoName{
    font-size: 3.5vh;
    font-weight: bold;
    color:#28255A;
    text-align: center;
    margin: 3vh 0 0 0;
}

.separator{
    height: .4vh;
    width: 80vw;
    border: none;
    border-radius: 2px;
    background-color: #ABD157;
}

.infoUser{
    font-size: 1.8vh;
    color:#28255A;
}

.infoSellerName{
    font-size: 2vh;
    font-weight: bold;
    color:#28255A;
    text-align: center;
    margin-top: 8vh;
    margin-bottom: 4vh;
}
.MuiTypography-body1{
    font-size: 2vh !important;
}
.MuiSvgIcon-root{
    width:3vh !important;
    height: 3vh !important;
}